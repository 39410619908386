<template>
  <div>
    <div class="search-bar">
      <div class="users-called">
        <small
          >Total Users: <span style="color: #28a745">{{ totalUsers }}</span>
        </small>
        <small
          >Total Top Up: <span style="color: #ee6463">{{ totalTopUp }}</span>
        </small>
      </div>
      <div class="choose-date">
        <el-date-picker
          type="daterange"
          v-model="dateRange"
          @change="searchUsers"
          start-placeholder="From"
          end-placeholder="To"
          name="pick-date"
          v-loading="rangeLoading">
        </el-date-picker>
      </div>
    </div>
    <div class="table-container">
      <el-table
        :data="users"
        height="50vh"
        :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
        v-loading="tableLoading">
        <el-table-column label="Name" prop="name"></el-table-column>
        <el-table-column label="Phone" prop="phone"></el-table-column>
        <el-table-column label="Email" prop="email"></el-table-column>
        <el-table-column
          label="Referred From"
          prop="referral"></el-table-column>
        <el-table-column label="Topped Up">
          <template slot-scope="scope">
            <span v-if="scope.row.top_up_status == 1" style="color: #28a745"
              >Topped Up Before</span
            >
            <span v-else style="color: #f7921c">Yet to top up</span>
          </template>
        </el-table-column>
        <el-table-column label="Created At">
          <template slot-scope="scope">
            <div>
              {{ moment(scope.row.created_at).format("ll hh:mm:ss A") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.is_follow_up == 0" style="color: #f7921c"
              ><strong>Not Called</strong></span
            >
            <span v-else style="color: #28a745"><strong>Called</strong></span>
          </template>
        </el-table-column>
        <!-- Action -->
        <el-table-column label="Action">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="toggleFollowUp(scope.row.id, scope.row.is_follow_up)"
              :class="[
                scope.row.is_follow_up == 0 ? 'is-called' : 'is-not-called',
              ]">
              <span v-if="scope.row.is_follow_up == 0"
                ><strong>Mark As Called</strong></span
              >
              <span v-else><strong>Mark As Not Called</strong></span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- Pagination -->
      <div class="col-md-12" style="text-align: center; margin-top: 2em">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import _ from "lodash";
  export default {
    data() {
      return {
        response: [],
        pageSize: 0,
        total: 0,
        currentPage: 1,
        page: 1,
        search: "",
        dateRange: [],
        tableLoading: false,
        rangeLoading: false,
        users_details: {
          users_called: 0,
          users_not_called: 0,
        },
        top_up_amount: 0,
      };
    },
    computed: {
      users() {
        return store.getters["admin/users/newUsers"].filter((user) => {
          return (
            user.name.toLowerCase().includes(this.search.toLowerCase()) ||
            user.phone.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      },
      totalUsers() {
        return (
          this.users_details.users_called + this.users_details.users_not_called
        );
      },
      totalTopUp() {
        if (this.response && this.response.length === 0) {
          return 0;
        }
        return this.response.data.reduce((sum, user) => {
          return sum + user.top_up_amount;
        }, 0).toLocaleString("en-US", {style:"currency", currency:"GHC"});;
      },
    },
    methods: {
      handleCurrentChange(val) {
        this.page = val;
        if (_.isEmpty(this.dateRange)) {
          this.tableLoading = true;
          store
            .dispatch("admin/users/getNewUsers", {
              page: val,
            })
            .then((data) => {
              this.tableLoading = false;
            })
            .finally(() => {
              this.tableLoading = false;
            });
          return;
        }
        this.tableLoading = true;
        let formattedFrom = new Date(this.dateRange[0]).toISOString();
        let formattedTo = new Date(this.dateRange[1]).toISOString();
        store
          .dispatch("admin/users/getNewUsers", {
            page: val,
            from: formattedFrom,
            to: formattedTo,
          })
          .then((data) => {
            this.tableLoading = false;
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
      searchUsers() {
        if (_.isEmpty(this.dateRange)) {
          this.tableLoading = true;
          store
            .dispatch("admin/users/getNewUsers", {
              page: 1,
            })
            .then((data) => {
              this.tableLoading = false;
            })
            .finally(() => {
              this.tableLoading = false;
            });
          return;
        }
        this.tableLoading = true;
        let formattedFrom = new Date(this.dateRange[0]).toISOString();
        let formattedTo = new Date(this.dateRange[1]).toISOString();

        store
          .dispatch("admin/users/getNewUsers", {
            page: 1,
            from: formattedFrom,
            to: formattedTo,
          })
          .then((data) => {
            this.pageSize = data.per_page;
            this.total = data.total;
            this.tableLoading = false;
            this.users_details.users_called = data.users_called;
            this.users_details.users_not_called = data.users_yet_to_call;
            this.response = data;
          })
          .finally(() => {
            this.tableLoading = false;
          });

        // set from and to for exporting
        store.dispatch("admin/users/setNewUsersToAndFrom", {
          from: formattedFrom,
          to: formattedTo,
        });
      },
      toggleFollowUp(id, is_follow_up) {
        this.tableLoading = true;
        let payload = {
          user_id: id,
          status: is_follow_up == 0 ? 1 : 0,
        };
        store
          .dispatch("admin/users/toggleFollowUp", payload)
          .then((response) => {
            this.$message({
              type: "success",
              message: "User called status updated successfully",
            });
            this.tableLoading = true;
            let formattedFrom = new Date(this.dateRange[0]).toISOString();
            let formattedTo = new Date(this.dateRange[1]).toISOString();
            store
              .dispatch("admin/users/getNewUsers", {
                page: this.page,
                from: formattedFrom,
                to: formattedTo,
              })
              .then((data) => {
                this.pageSize = data.per_page;
                this.total = data.total;
                this.tableLoading = false;
                this.users_details.users_called = data.users_called;
                this.users_details.users_not_called = data.users_yet_to_call;
              })
              .finally(() => {
                this.tableLoading = false;
              });
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: "There was an error updating user status",
            });
          })
          .finally(() => {
            // this.tableLoading = false
          });
      },
    },
    mounted() {
      this.tableLoading = true;
      store
        .dispatch("admin/users/getNewUsers", { page: 1 })
        .then((response) => {
          this.total = response.total;
          this.tableLoading = false;
          this.pageSize = response.per_page;
          this.users_details.users_called = response.users_called;
          this.users_details.users_not_called = response.users_yet_to_call;
          this.response = response.data
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.response.data.message,
          });
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  };
</script>
<style scoped>
  .search-bar {
    display: flex;
    justify-content: center;
    margin-block: 1.5rem;
    align-items: center;
  }

  .is-not-called {
    background-color: #ee6463;
    border-color: #ee6463;
  }

  .is-called {
    background-color: #28a745;
    border-color: #28a745;
  }

  .users-called {
    align-self: flex-start;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
  }

  .choose-date {
    width: 100%;
  }
</style>
